import { Controller } from "@hotwired/stimulus"
import ActionsModalService from "../services/ActionsModal"

export default class extends Controller {
  static targets = ["emailInput", "emailSubmit", "emailError"]

  /**
   *
   * @see https://stimulus.hotwired.dev/reference/outlets
   */
  static outlets = ["modal"]

  connect() {
    this.actionsModalService = new ActionsModalService("admins")
  }

  openActionsMenu(event) {
    this.actionsModalService.openActions(event)
  }

  hideActions() {
    this.actionsModalService.hideActions()
  }

  closeOtherModals(modalId) {
    const otherModals = this.modalOutlet.containerTargets.filter((wrapper) => wrapper.dataset.id !== modalId)

    otherModals.forEach((form) => {
      form.classList.add("hidden")
    })
  }

  resend(event) {
    event.preventDefault()
    this.actionsModalService.hideActions()

    if (this.hasModalOutlet) {
      this.closeOtherModals("resendConfirmation")

      const admin = event.target.closest("[data-actions-modal='row']")
      const subheadingText = `We will resend the invitation code to: ${admin.dataset.email}`
      const container = this.modalOutlet.containerTargets.find((wrapper) => wrapper.dataset.id === "resendConfirmation")

      container.querySelector("[data-modal-target='subheading']").innerText = subheadingText
      container.querySelector("[data-admins-target='form']").action = `/${admin.dataset.route}/${admin.dataset.id}/resend`
      container.classList.remove("hidden")
      this.modalOutlet.open(event)
    } else {
      console.log('no modal outlet')
    }
  }

  revoke(event) {
    event.preventDefault()
    this.actionsModalService.hideActions()

    if (this.hasModalOutlet) {
      this.closeOtherModals("revokeConfirmation")

      const admin = event.target.closest("[data-actions-modal='row']")
      const subheadingText = `We will revoke the invitation code for: ${admin.dataset.email}`
      const container = this.modalOutlet.containerTargets.find((wrapper) => wrapper.dataset.id === "revokeConfirmation")

      container.querySelector("[data-modal-target='subheading']").innerText = subheadingText
      container.querySelector("[data-admins-target='form']").action = `/${admin.dataset.route}/${admin.dataset.id}/revoke`
      container.classList.remove("hidden")
      this.modalOutlet.open(event)
    } else {
      console.log('no modal outlet')
    }
  }

  deleteAdmin(event) {
    event.preventDefault()
    this.actionsModalService.hideActions()

    if (this.hasModalOutlet) {
      this.closeOtherModals("deleteAdminConfirmation")

      const admin = event.target.closest("[data-actions-modal='row']")
      const subheadingText = `We will delete the admin account for: ${admin.dataset.name}`
      const container = this.modalOutlet.containerTargets.find((wrapper) => wrapper.dataset.id === "deleteAdminConfirmation")

      container.querySelector("[data-modal-target='subheading']").innerText = subheadingText
      container.querySelector("[data-admins-target='form']").action = `/admins/${admin.dataset.id}`
      container.classList.remove("hidden")
      this.modalOutlet.open(event)
    } else {
      console.log('no modal outlet')
    }
  }

  inviteAdmin() {
    this.enableEmailSubmit()
  }

  enableEmailSubmit() {
    this.emailSubmitTarget.disabled = false
    this.emailSubmitTarget.classList.remove('inactive')
  }

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  submitEmailAddress(event) {
    event.preventDefault()

    if (this.emailSubmitTarget.classList.contains('inactive')) {
      return
    }

    this.emailErrorTarget.classList.remove('cstm-block')

    if (this.hasModalOutlet) {
      this.closeOtherModals("sendInvitationConfirmation")
      const emailAddress = this.emailInputTarget.value
      const validEmail = this.validateEmail(emailAddress)

      if (emailAddress && validEmail) {
        const container = this.modalOutlet.containerTargets.find((wrapper) => wrapper.dataset.id === "sendInvitationConfirmation")
        const subheadingText = `We will email an invitation code to: ${this.emailInputTarget.value}`

        container.querySelector("[data-modal-target='subheading']").innerText = subheadingText
        container.classList.remove("hidden")
        this.modalOutlet.open(event)
      } else {
        this.emailErrorTarget.classList.add('cstm-block')
      }

    } else {
      console.log('no modal outlet')
    }
  }
}
