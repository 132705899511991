/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes
 */
class ActionsModal {
  constructor(controllerName) {
    this.controllerName = controllerName
    this.controllerContainer = document.querySelector(`[data-controller*='${this.controllerName}']`)
  }

  hideActions() {
    const threeDotsTargets = this.controllerContainer.querySelectorAll("[data-actions-modal='threeDots']")
    const actionsNavTargets = this.controllerContainer.querySelectorAll("[data-actions-modal='actionsNav']")

    threeDotsTargets.forEach((threeDots) => {
      if (threeDots.classList.contains("w--open")) {
        threeDots.classList.remove("w--open")
      }
    })

    actionsNavTargets.forEach((actionsNav) => {
      if (actionsNav.classList.contains("w--open")) {
        actionsNav.classList.remove("w--open")
      }
    })
  }

  openActions(event) {
    event.stopPropagation()
    this.hideActions()

    const actionsContainer = event.target.closest("[data-actions-modal='actionsContainer']")

    actionsContainer.querySelector("[data-actions-modal='threeDots']").classList.add("w--open")
    actionsContainer.querySelector("[data-actions-modal='actionsNav']").classList.add("w--open")
  }
}

export default ActionsModal
